/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import CustomNoRowsOverlay from "./../utils/styles";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import EditMerchant from "../components/modals/EditMerchant";
import ActionButton from "../components/buttons/ActionButton";
import { reactIcons } from "../utils/icons";
import KycDetails from "../components/modals/KycDetails";
import { getAuthData } from "../utils/apiHandlers";

const KycRequest = () => {
  const [isEditUserOpen, setIsEditUserOpen] = useState(false);
  const [openKycDetailModal, setOpenKycDetailModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [kycItem, setKycItem] = useState({});

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 8,
  });
  const [rowCountState, setRowCountState] = useState(0);
  const [refresher, setRefresher] = useState(false);
  const [type, setType] = useState("Individual");
  const [kycStatus, setStatus] = useState("Pending");
  const capitalized = (word) => word.charAt(0).toUpperCase() + word.slice(1);

  const kycDetailModalHandler = (params) => {
    setKycItem(params.row);
    setOpenKycDetailModal(true);
  };
  const userType = "KYC";
  let columns = [
    {
      field: "_id",
      headerName: "S.No.",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "fullname",
      headerName: "Full Name",
      type: "string",
      flex: 2,
      renderCell: (params) => {
        return (
          <div>
            <span>{params.row.firstname + " " + params.row.lastname}</span>
          </div>
        );
      },
    },

    {
      field: "status",
      headerName: "KYC Type",
      type: "string",
      flex: 2,
      renderCell: (params) => {
        return (
          <div>
            <span>{capitalized(params.row.verificationType)}</span>
          </div>
        );
      },
    },
    {
      field: "registrationStatus",
      headerName: "KYC Status",
      type: "string",
      flex: 2,
      renderCell: (params) => {
        return (
          <div>
            <span
              className={`${
                params.row.verificationStatus == "Verified"
                  ? "text-green-700"
                  : params.row.verificationStatus == "Rejected"
                  ? "text-red-700"
                  : "text-yellow-700"
              }`}
            >
              {params.row.verificationStatus == "Verified" ||
              params.row.verificationStatus == "Rejected"
                ? capitalized(params.row.verificationStatus)
                : "Pending"}
            </span>
          </div>
        );
      },
    },
    {
      field: "isActive",
      headerName: "Status",
      type: "string",
      flex: 2,
      renderCell: (params) => {
        return (
          <div>
            <span>{params.row.status}</span>
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      width: 150,
      renderCell: (params) => {
        return userType === "KYC" ? (
          <>
            <span
              className="text-[20px] cursor-pointer"
              onClick={() => kycDetailModalHandler(params)}
            >
              {reactIcons.eyeVisible}
            </span>
          </>
        ) : (
          <ActionButton
            data={params?.row}
            isEditUserOpen={isEditUserOpen}
            setIsEditUserOpen={setIsEditUserOpen}
            // getMerchantsInfo={getMerchantsInfo}
            usersType={type}
          />
        );
      },
    },
  ];
  const [rows, setRows] = useState([]);

  useEffect(() => {
    getKYCMerchantInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, kycStatus, paginationModel, refresher]);

  const getKYCMerchantInfo = async () => {
    setIsLoading(true);
    const skip = paginationModel.page * 8 || 0;
    const res = await getAuthData(
      `/user/verifications?skip=${skip}&take=8&type=${type}&status=${kycStatus}`
    );
    const { status, data } = res;
    if (status >= 200 && status < 300) {
      setIsLoading(false);
      setRows(
        data?.data.map((item, index) => {
          return {
            ...item,
            ...item.merchant,
            _id: skip + index + 1,
          };
        })
      );
      setRowCountState(data?.count || 0);
    } else {
      setIsLoading(false);
      toast.error(data.message);
    }
  };

  const selectboxHandler = (e) => {
    setStatus(e.target.value);
  };

  useEffect(() => {
    getKYCMerchantInfo();
  }, []);

  return (
    <>
      <div className="w-full mt-10 data-grid">
        <div className="flex justify-between mb-6 items-center">
          <h4>KYC Requests</h4>
        </div>
        <div className="flex items-center justify-between gap-2">
          <div className="flex gap-6 items-center mb-4">
            <button
              className={`btn ${
                type === "Individual" ? "btn-primary" : "btn-outline-primary"
              }`}
              onClick={() => {
                setType("Individual");
              }}
            >
              Individual
            </button>
            <button
              className={`btn ${
                type === "Corporate" ? "btn-primary" : "btn-outline-primary"
              }`}
              onClick={() => {
                setType("Corporate");
              }}
            >
              Corporate
            </button>
          </div>

          <div className="max-w-[200px] flex items-center gap-3">
            <label>Filter</label>
            <select value={kycStatus} onChange={selectboxHandler}>
              <option value="Pending">Pending</option>
              <option value="Verified">Approved</option>
              <option value="Rejected">Rejected</option>
            </select>
          </div>
        </div>

        <div className=" h-[600px] w-full mt-4">
          <DataGrid
            loading={isLoading}
            rows={rows}
            getRowId={(row) => {
              return row._id;
            }}
            getEstimatedRowHeight={() => 52}
            columns={columns}
            initialState={{
              paginationModel: {
                pageSize: 8,
              },
            }}
            rowCount={rowCountState}
            pageSizeOptions={[8]}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            slots={{
              loadingOverlay: LinearProgress,
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            disableColumnMenu
            disableRowSelectionOnClick
          />
        </div>
      </div>
      {/* <EditMerchant
        open={isEditUserOpen}
        handleClose={() => setIsEditUserOpen(false)}
        isKycMechant={isKycMechant}
      /> */}
      <KycDetails
        open={openKycDetailModal}
        handleClose={() => setOpenKycDetailModal((pre) => !pre)}
        kycItem={kycItem}
        refresher={() => setRefresher(!refresher)}
      />
    </>
  );
};

export default KycRequest;
