import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { isYupError, parseYupError } from "../../utils/Yup";
import { userValidation } from "../../utils/validation";
import { toast } from "react-toastify";
import * as api from "../../redux/api";
const initialState = {
  city: "",
  country: "",
  email: "",
  fullname: "",
  mobile: "",
  telegram: "",
  whatsapp: "",
};
const EditUser = ({ handleClose, open, data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(initialState);
  const handleCloseModal = () => {
    handleClose();
    setForm(initialState);
    setFormError({});
  };
  const [formError, setFormError] = useState({});
  const handleSubmit = async (e) => {
    setIsLoading(true);
    try {
      let _temp = {
        ...form,
        whatsapp: form.whatsapp === "" ? null : form.whatsapp,
      };
      setFormError({});
      await userValidation.validate(_temp, {
        abortEarly: false,
      });

      const res = await api.updateUserDetails(data.id, _temp);
      if (res.data.status === "success") {
        setIsLoading(false);
        toast.success("Updated Successfully");
        handleCloseModal();
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      } else {
        toast.error(error.response.data.message);
      }
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm({
      ...form,
      [name]: value,
    });
    setFormError({
      ...formError,
      [name]: "",
    });
  };

  useEffect(() => {
    if (data) {
      setForm({
        city: data?.city || "",
        country: data?.country || "",
        email: data?.email || "",
        fullname: data?.fullname || "",
        mobile: data?.mobile
          ? data?.mobile?.length > 10
            ? data?.mobile?.slice(3)
            : data?.mobile
          : "",
        telegram: data?.meta?.telegram || "",
        whatsapp: data?.meta?.whatsapp || "",
      });
    }
  }, [data]);
  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {" "}
          <h4 className=" text-center">Edit User Details</h4>
        </DialogTitle>
        <DialogContent dividers={"paper"}>
          <div className="   w-full ">
            <div className=" flex flex-col gap-2 py-6 px-6 ">
              <div className="input_field">
                <label htmlFor="">Full Name</label>
                <input
                  type="text"
                  placeholder="Full Name "
                  name="fullname"
                  onChange={handleChange}
                  value={form.fullname}
                />
                {formError.fullname && (
                  <div className="form-eror">{formError.fullname}</div>
                )}
              </div>
              <div className="input_field">
                <label htmlFor="">Email</label>
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  onChange={handleChange}
                  value={form.email}
                />
                {formError.email && (
                  <div className="form-eror">{formError.email}</div>
                )}
              </div>
              <div className="input_field">
                <label htmlFor="">Mobile</label>
                <input
                  type="number"
                  placeholder="Mobile No."
                  name="mobile"
                  onChange={handleChange}
                  value={form.mobile}
                />
                {formError.mobile && (
                  <div className="form-eror">{formError.mobile}</div>
                )}
              </div>
              <div className="input_field">
                <label htmlFor="">City</label>
                <input
                  type="text"
                  placeholder="City"
                  name="city"
                  onChange={handleChange}
                  value={form.city}
                />
                {formError.city && (
                  <div className="form-eror">{formError.city}</div>
                )}
              </div>
              <div className="input_field">
                <label htmlFor="">Country</label>
                <input
                  type="text"
                  placeholder="Country"
                  name="country"
                  onChange={handleChange}
                  value={form.country}
                />
                {formError.country && (
                  <div className="form-eror">{formError.country}</div>
                )}
              </div>
              <div className="input_field">
                <label htmlFor="">Whatsapp</label>
                <input
                  type="number"
                  placeholder="Whatsapp No."
                  name="whatsapp"
                  onChange={handleChange}
                  value={form.whatsapp}
                />
                {formError.whatsapp && (
                  <div className="form-eror">{formError.whatsapp}</div>
                )}
              </div>
              <div className="input_field">
                <label htmlFor="">Telegram</label>
                <input
                  type="text"
                  placeholder="Telegram Id"
                  name="telegram"
                  onChange={handleChange}
                  value={form.telegram}
                />
                {formError.telegram && (
                  <div className="form-eror">{formError.telegram}</div>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={handleSubmit}
            disabled={isLoading}
            className={`btn btn-primary min-w-[200px]  ${
              isLoading
                ? "bg-red-500 border-red-500 opacity-50 disabled:pointer-events-none"
                : undefined
            }`}
          >
            {isLoading ? "Loading" : "Submit"}
          </button>
          <button
            onClick={handleCloseModal}
            type="button"
            className="btn btn-danger min-w-[200px]"
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditUser;
