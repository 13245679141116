import React, { useState } from "react";
import { FiAward } from "react-icons/fi";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import CustomNoRowsOverlay from "./../utils/styles";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getAuth } from "./../redux/features/authSlice";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import { reactIcons } from "../utils/icons";
import { getAuthData } from "../utils/apiHandlers";
import CopyToClipboard from "react-copy-to-clipboard";
import moment from "moment/moment";
const DashboardLanding = () => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowCountState, setRowCountState] = useState(0);
  const [transactionType, setTransactiontype] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 8,
  });
  const [dashboardData, setDashboardDat] = useState({});
  const getKYCMerchantInfo = async () => {
    setIsLoading(true);
    const res = await getAuthData(`/dashboard/stats`);
    const { status, data } = res;
    if (status >= 200 && status < 300) {
      setIsLoading(false);
      setDashboardDat(data);
    } else {
      setIsLoading(false);
      toast.error(data.message);
    }
  };

  useEffect(() => {
    getKYCMerchantInfo();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "S.No.",
      type: "number",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "username",
      headerName: "User name",
      type: "number",
      headerAlign: "center",
      align: "center",
      flex: 2,
    },
    {
      field: "currencyType",
      headerName: "Currency",
      type: "number",
      headerAlign: "center",
      align: "center",
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            <div>
              <span className="text-sm">
                {params.row?.currencyType === "VTT"
                  ? "VTSG"
                  : params.row?.currencyType}
              </span>
            </div>
          </>
        );
      },
    },
    {
      field: "timestamp",
      headerName: "Date/Time",
      type: "string",
      flex: 2,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <div>
              <span className="text-sm">
                {moment(params.row.timestamp).format("DD/MM/YYYY")}
              </span>
            </div>
            {/* ,
            <br />
            <div>
              <span className="text-sm">
                {moment(params.row.timestamp).format("hh:mm A")}
              </span>
            </div> */}
          </>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      type: "number",
      headerAlign: "center",
      align: "center",
      flex: 2,
      renderCell: (params) => {
        return (
          <div>
            <span
              className={`text-sm ${
                params.row.type === "Debit" ? "text-green-700" : "text-red-700"
              }`}
            >
              {params.row.type === "Debit" ? "Credit" : "Debit"}
            </span>
          </div>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount ( No.of token)",
      flex: 3,
      type: "string",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            {params.row.currencyType !== "USDT" ? (
              <span className="text-sm">
                {(params.row.amount / 1000000000000000000).toFixed(2) || 0}
              </span>
            ) : (
              <span className="text-sm">{params.row.price || 0}</span>
            )}
          </div>
        );
      },
    },
    {
      field: "hash",
      headerName: "Transaction Hash",
      type: "string",
      flex: 3,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return (
          <div>
            <span className="flex">
              <span className="text-xs lg:text-sm ">
                {" "}
                {params.row.hash.slice(0, 6) +
                  "........" +
                  params.row.hash.slice(-3)}
              </span>
              <CopyToClipboard
                text={params.row.hash}
                onCopy={() => toast.success("Copied SuccessFully")}
              >
                <span className="mx-1 text-xl text-zinc-400 cursor-pointer w-5 h-5 rounded-lg flex-center hover:bg-zinc-100">
                  {reactIcons.copy}
                </span>
              </CopyToClipboard>
            </span>
          </div>
        );
      },
    },
    {
      field: "paymentMode",
      headerName: "Payment Mode ",
      type: "string",
      flex: 3,
      headerAlign: "center",
      align: "center",
    },
  ];

  useEffect(() => {
    const getTransactionData = async () => {
      setIsLoading(true);
      const querry =
        transactionType === "Debit" || transactionType === "Credit"
          ? `&type=${transactionType}`
          : "";
      const res = await getAuthData(
        `/transactions?skip=${paginationModel.page * 8}&take=8${querry}`
      );
      const { status, data } = res;
      if (status >= 200 && status < 300) {
        setIsLoading(false);
        setRowCountState(data.count);
        setRows(
          data.data
            .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
            .map((item, index) => {
              return { ...item, id: 8 * paginationModel.page + index + 1 };
            })
        );
      } else {
        setIsLoading(false);
        toast.error(data.message);
      }
    };
    try {
      getTransactionData();
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
    dispatch(getAuth());
    setRowCountState((prevRowCountState) =>
      rowCountState !== undefined ? rowCountState : prevRowCountState
    );
  }, [
    dispatch,
    paginationModel,
    rowCountState,
    setRowCountState,
    transactionType,
  ]);

  const ventursaFormat = 1000000000000000000;

  return (
    <div>
      <div className="upper flex flex-col gap-4">
        <h4 className="flex items-center gap-2">
          Dashboard <FiAward className="text-[22px] text-theme-darker" />{" "}
        </h4>
      </div>
      <div className=" mt-6  w-full flex relative px-4">
        <div className="p-3 xl:p-4 w-[25%] mr-4  shadow-lg border-2 border-grey-30 border-l-[6px]  border-l-gray-500 rounded-lg flex items-center justify-between gap-2">
          <div className="space-y-1 flex-1">
            <h6 className="text-theme-text2 mb-2 text-sm xl:text-[16px]">
              Market cap
            </h6>
            <div className="flex ">
              <span className="xl:text-base text-sm font-bold">
                {(dashboardData?.totalSupply / ventursaFormat).toFixed(2) || 0}
              </span>{" "}
            </div>
          </div>
        </div>
        <div className="p-3 xl:p-4 w-[25%] mr-4  shadow-lg border-2 border-grey-30 border-l-[6px] border-l-green-dark rounded-lg flex items-center justify-between gap-2">
          <div className="space-y-1 flex-1">
            <h6 className="text-theme-text2 mb-2 text-sm xl:text-[16px]">
              Active Holders
            </h6>
            <div className="flex">
              <span className="xl:text-base text-sm font-bold">
                {dashboardData?.activeUserCount || 0}
              </span>{" "}
            </div>
          </div>
        </div>
        <div className="p-3 xl:p-4 w-[25%] mr-4  shadow-lg border-2 border-grey-30 border-l-[6px] border-l-pink-500 rounded-lg flex items-center justify-between gap-2">
          <div className="space-y-1 flex-1">
            <h6 className="text-theme-text2 mb-2 text-sm xl:text-[16px]">
              Total Supply
            </h6>
            <div className="flex ">
              <span className="xl:text-base text-sm font-bold">
                {(dashboardData?.totalCoins / ventursaFormat).toFixed(2) || 0}
              </span>{" "}
            </div>
          </div>
        </div>
        <div className="p-3 xl:p-4 w-[25%] mr-4  shadow-lg border-2 border-grey-30 border-l-[6px] border-l-yellow-dark rounded-lg flex items-center justify-between gap-2">
          <div className="space-y-1 flex-1">
            <h6 className="text-theme-text2 mb-2 text-sm xl:text-[16px]">
              KYC Approved User Count
            </h6>
            <div className="flex ">
              <span className="xl:text-base text-sm font-bold">
                {dashboardData?.totalKycApprovedUser || 0}
              </span>{" "}
            </div>
          </div>
        </div>
      </div>
      {/* tables */}
      <div className="w-full mt-10 data-grid">
        <div className="flex justify-between mb-3">
          <h4 className="flex items-center gap-2 mb-2">Transactions</h4>
          <select
            className="min-w-[150px] max-w-[170px]"
            name="usersType"
            id="usersType"
            value={transactionType}
            onChange={(e) => setTransactiontype(e.target.value)}
          >
            <option value="">All</option>
            <option value="Credit">Debit </option>
            <option value="Debit">Credit</option>
          </select>
        </div>
        <div className=" h-[600px] w-full">
          <DataGrid
            loading={isLoading}
            rows={rows}
            getEstimatedRowHeight={() => 52}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 8,
                },
              },
            }}
            rowCount={rowCountState}
            pageSizeOptions={[8]}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            slots={{
              toolbar: GridToolbar,
              loadingOverlay: LinearProgress,
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            disableColumnMenu
            disableRowSelectionOnClick
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardLanding;
