/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import CustomNoRowsOverlay from "./../utils/styles";
import { toast } from "react-toastify";
import { reactIcons } from "./../utils/icons";
import EditUser from "../components/modals/EditUser";
import { getAuthData, postAuthData } from "../utils/apiHandlers";
import CopyToClipboard from "react-copy-to-clipboard";
const Users = () => {
  const [searchText, setSearchText] = useState("");
  const [userData, setUserData] = useState(null);
  const [isEditUserOpen, setIsEditUserOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 8,
  });
  const [rowCountState, setRowCountState] = useState(0);
  const [statusKey, setStatusKey] = useState("Active");
  const [listType, setListType] = useState("All");

  const handleBlockUser = async (id) => {
    try {
      const res = await postAuthData(`/users/${id}/Blocked`);
      if (res.data.status === "success") {
        toast.success("Blocked Successfully");
        getUserData();
      } else {
        toast.error("Error Occurred");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleUnBlockUser = async (id) => {
    try {
      const res = await postAuthData(`/users/${id}/Active`);

      if (res.data.status === "success") {
        toast.success("UnBlocked Successfully");
        getUserData();
      } else {
        toast.error("Error Occurred");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const walletAddress = "jgfdjhg55fg5fgf4gdf7gfg8gf5g7g58g";
  const columns = [
    {
      field: "_id",
      headerName: "S.No.",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 60,
      renderCell: (params) => {
        return (
          <div>
            <span className="text-sm">{params.row._id}</span>
          </div>
        );
      },
    },

    {
      field: "fullname",
      headerName: "Full Name",
      type: "string",
      flex: 2,
      renderCell: (params) => {
        return (
          <div>
            <span className="text-sm capitalize">{params.row.fullname}</span>
          </div>
        );
      },
    },
    {
      field: "username",
      headerName: "Username",
      type: "string",
      flex: 2,
      renderCell: (params) => {
        return (
          <div>
            <span className="text-sm">{params.row.username}</span>
          </div>
        );
      },
    },
    {
      field: "walletAddress",
      headerName: "Wallet Address",
      type: "string",
      flex: 2,
      renderCell: (params) => {
        return (
          <div>
            {params.row.Wallet ? (
              <span className="flex">
                <span className="text-xs lg:text-sm ">
                  {" "}
                  {params.row?.Wallet?.address.slice(0, 6) +
                    "........" +
                    params.row?.Wallet?.address.slice(-3)}
                </span>
                <CopyToClipboard
                  text={params.row?.Wallet?.address}
                  onCopy={() => toast.success("Copied SuccessFully")}
                >
                  <span className="mx-1 text-xl text-zinc-400 cursor-pointer w-5 h-5 rounded-lg flex-center hover:bg-zinc-100">
                    {reactIcons.copy}
                  </span>
                </CopyToClipboard>
              </span>
            ) : (
              <span>N/A</span>
            )}
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      type: "string",
      flex: 3,
      renderCell: (params) => {
        return (
          <div>
            <span className="text-sm">{params.row.email}</span>
          </div>
        );
      },
    },
    {
      field: "country",
      headerName: "Country",
      type: "string",
      flex: 2,
      renderCell: (params) => {
        return (
          <div>
            <span className="text-sm">{params.row.address.country}</span>
          </div>
        );
      },
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   type: "string",
    //   width: 120,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         <span className="text-sm">{params.row.status}</span>
    //       </div>
    //     );
    //   },
    // },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      width: 80,
      renderCell: (params) => {
        return (
          <div className="flex items-center gap-4 justify-center">
            {/* <button
              onClick={() => {
                setIsEditUserOpen(true);
                setUserData(params.row);
              }}
              className="w-10 h-10 z-10 cursor-pointer text-[18px] bg-zinc-200 hover:bg-zinc-300 rounded-md flex-center"
            >
              {reactIcons.edit}
            </button> */}
            {params.row.status === "Active" ? (
              <button
                onClick={() => handleBlockUser(params.row.id)}
                className="px-4 text-xs lg:text-sm xl:text-base py-2 rounded-md bg-zinc-200 hover:bg-zinc-300"
              >
                Block
              </button>
            ) : (
              <button
                onClick={() => handleUnBlockUser(params.row.id)}
                className="px-4 text-xs lg:text-sm xl:text-base py-2 rounded-md bg-zinc-200 hover:bg-zinc-300"
              >
                UnBlock
              </button>
            )}
          </div>
        );
      },
    },
  ];

  const getUserData = async () => {
    setIsLoading(true);
    const optionalKey = listType !== "All" ? `&type=${listType}` : "";
    const optionalKey2 = listType === "All" ? `&status=${statusKey}` : "";
    const response = await getAuthData(
      `/users?skip=${
        paginationModel.page * 8
      }&take=8&search=${searchText}${optionalKey2}${optionalKey}`
    );
    const { data, status } = response;
    if (status >= 200 && status < 300) {
      setIsLoading(false);
      setRowCountState(data.count);
      setRows(
        data.data.map((item, index) => {
          return {
            ...item,
            _id: 8 * paginationModel.page + index + 1,
            fullname: item.firstname + " " + item.lastname,
          };
        })
      );
    } else {
      setIsLoading(false);
      toast.error(data.message);
    }
  };



  useEffect(() => {
    getUserData();
    setRowCountState((prevRowCountState) =>
      rowCountState !== undefined ? rowCountState : prevRowCountState
    );
  }, [
    paginationModel,
    rowCountState,
    setRowCountState,
    searchText,
    listType,
    statusKey,
  ]);

  return (
    <>
      <div className="w-full mt-10 data-grid">
        <div className="flex gap-6 items-center mb-4">
          <button
            className={`btn ${
              listType === "All" ? "btn-primary" : "btn-outline-primary"
            }`}
            onClick={() => setListType("All")}
          >
            All Users
          </button>
          <button
            className={`btn ${
              listType === "SevenDayActive"
                ? "btn-primary"
                : "btn-outline-primary"
            }`}
            onClick={() => setListType("SevenDayActive")}
          >
            Last Seven Days Active Users
          </button>
          <button
            className={`btn ${
              listType === "SevenDayInActive"
                ? "btn-primary"
                : "btn-outline-primary"
            }`}
            onClick={() => setListType("SevenDayInActive")}
          >
            Last Seven Days InActive Users
          </button>
        </div>
        {listType === "All" && (
          <div className="flex justify-between items-center gap-6 ">
            {/* <h4>Level Bonus</h4> */}
            <div className="flex-1 max-w-[800px] flex justify-center items-center">
              <input
                className="w-full"
                type="text"
                value={searchText}
                placeholder="search by name"
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
            <div className="form-select-custom">
              <select
                className="min-w-[200px] w-full"
                name="usersType"
                id="usersType"
                value={statusKey}
                onChange={(e) => setStatusKey(e.target.value)}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
                <option value="Blocked">Blocked</option>
              </select>
              <span className="ay-center right-2 text-zinc-500 text-[28px] pointer-events-none ">
                {reactIcons.arrowDown}
              </span>
            </div>
          </div>
        )}
        <div className=" h-[600px] w-full mt-4">
          <DataGrid
            loading={isLoading}
            rows={rows}
            getRowId={(row) => {
              return row._id;
            }}
            getEstimatedRowHeight={() => 52}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 8,
                },
              },
            }}
            rowCount={rowCountState}
            pageSizeOptions={[8]}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            slots={{
              loadingOverlay: LinearProgress,
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            disableColumnMenu
            disableRowSelectionOnClick
          />
        </div>
      </div>
      <EditUser
        open={isEditUserOpen}
        handleClose={() => setIsEditUserOpen(false)}
        data={userData}
      />
    </>
  );
};

export default Users;
