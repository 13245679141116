/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import * as api from "../redux/api";
import { changePasswordValidation } from "../utils/validation";
import { isYupError, parseYupError } from "../utils/Yup";
import { toast } from "react-toastify";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { patchAuthData, postAuthData } from "../utils/apiHandlers";
import { getAuth } from "../redux/features/authSlice";

const Settings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [eye, setEye] = useState(false);
  const [eye1, setEye1] = useState(false);
  const [eye2, setEye2] = useState(false);
  const [isDetail, setIsDetail] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [form, setForm] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [formError, setFormError] = useState({});
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      setForm({
        ...form,
        firstname: user.firstname || "",
        lastname: user.lastname || "",
        email: user.email || "",
        username: user.username || "",
        country: user.country || "",
      });
    }
  }, [user, isLoading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
    setFormError({
      ...formError,
      [name]: "",
    });
  };

  const handleUserInfoUpdate = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      const res = await patchAuthData("/admin", form);
      const { status, data } = res;
      if (status >= 200 && status < 300) {
        setIsLoading(false);
        toast.success("Updated Successfully");
        dispatch(getAuth());
      } else {
        setIsLoading(false);
        toast.error(data.message);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleChangePasswordSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      setFormError({});
      await changePasswordValidation.validate(form, {
        abortEarly: false,
      });
      const res = await postAuthData("/admin/change-password", form);
      if (res.data.status === "success") {
        setIsLoading(false);
        toast.success("Password Changed Successfully");
        dispatch(getAuth());
        setForm({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      } else {
        setIsLoading(false);
        toast.error(res?.data?.message);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      } else {
        toast.error(error.data.message);
      }
      setIsLoading(false);
    }
  };

  const handleDetailsChange = () => {
    setIsDetail(!isDetail);
    setForm({});
    setFormError({});
  };

  return (
    <div className="w-full mt-10 data-grid">
      <div className="flex justify-between mb-6 items-center">
        <h4>Profile</h4>
      </div>
      {!isDetail ? (
        <div className="w-full flex justify-start items-center">
          <div className="max-w-[500px] m-auto">
            <div className="flex flex-col gap-2 py-6 px-6 ">
              <div className="input_field mt-2">
                <label htmlFor="">User Name</label>
                <input
                  type="text"
                  placeholder="Please enter user name"
                  onChange={handleChange}
                  name="username"
                  value={form.username}
                  className="mt-2"
                />
              </div>

              <div className="input_field mt-2">
                <label htmlFor="">First Name</label>
                <input
                  type="text"
                  placeholder="Please enter first name"
                  onChange={handleChange}
                  name="firstname"
                  value={form.firstname}
                  className="mt-2"
                />
              </div>

              <div className="input_field mt-2">
                <label htmlFor="">Last Name</label>
                <input
                  type="text"
                  placeholder="Please enter last name"
                  onChange={handleChange}
                  name="lastname"
                  value={form.lastname}
                  className="mt-2"
                />
              </div>

              <div className="input_field mt-2">
                <label htmlFor="">Email</label>
                <input
                  type="text"
                  placeholder="Please enter email"
                  onChange={handleChange}
                  name="email"
                  value={form.email}
                  className="mt-2"
                />
              </div>

              <div className="input_field mt-2">
                <label htmlFor="">Country</label>
                <input
                  type="text"
                  placeholder="Please enter country name"
                  onChange={handleChange}
                  name="country"
                  value={form.country}
                  className="mt-2"
                />
              </div>
            </div>
            <div className="flex justify-center gap-5">
              <button
                type="button"
                onClick={handleUserInfoUpdate}
                className="btn btn-primary text-black min-w-[200]"
              >
                Update
              </button>
              <button
                onClick={() => {
                  handleDetailsChange();
                  setIsLoading(false);
                }}
                type="button"
                className="btn btn-danger"
              >
                Change Password
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full flex justify-start items-center">
          <div className="max-w-[500px] m-auto">
            <div className="flex flex-col gap-2 py-6 px-6 ">
              <div>
                <div className="input_field relative mt-3">
                  <label htmlFor="">Old Password</label>
                  <input
                    type={eye ? "text" : "password"}
                    placeholder="Old Password"
                    name="oldPassword"
                    onChange={handleChange}
                    className="mt-2"
                    value={form.oldPassword || ""}
                    readOnly={readOnly}
                    onFocus={() => setReadOnly(false)}
                    onBlur={() => setReadOnly(true)}
                  />

                  <span
                    className="absolute right-4 top-[50px] translate-y-[-50%] cursor-pointer z-10"
                    onClick={() => setEye(!eye)}
                  >
                    {eye ? (
                      <AiFillEye className="text-2xl text-theme-text" />
                    ) : (
                      <AiFillEyeInvisible className="text-2xl text-theme-text" />
                    )}
                  </span>
                </div>
                {formError.oldPassword && (
                  <div className="form-eror">{formError.oldPassword}</div>
                )}
              </div>

              <div className="input_field relative mt-3">
                <label htmlFor="">New Password</label>
                <input
                  type={eye1 ? "text" : "password"}
                  placeholder="New Password"
                  name="newPassword"
                  onChange={handleChange}
                  className="mt-2"
                  value={form.newPassword || ""}
                  readOnly={readOnly}
                  onFocus={() => setReadOnly(false)}
                  onBlur={() => setReadOnly(true)}
                />

                <span
                  className="absolute right-4 top-[50px] translate-y-[-50%] cursor-pointer z-10"
                  onClick={() => setEye1(!eye1)}
                >
                  {eye1 ? (
                    <AiFillEye className="text-2xl text-theme-text" />
                  ) : (
                    <AiFillEyeInvisible className="text-2xl text-theme-text" />
                  )}
                </span>
              </div>
              {formError.newPassword && (
                <div className="form-eror">{formError.newPassword}</div>
              )}
              <div className="input_field relative mt-3">
                <label htmlFor="">Confirm New Password</label>
                <input
                  type={eye2 ? "text" : "password"}
                  placeholder="Confirm New Password"
                  name="confirmPassword"
                  onChange={handleChange}
                  className="mt-2"
                  value={form.confirmPassword || ""}
                  readOnly={readOnly}
                  onFocus={() => setReadOnly(false)}
                  onBlur={() => setReadOnly(true)}
                />

                <span
                  className="absolute right-4 top-[50px] translate-y-[-50%] cursor-pointer z-10"
                  onClick={() => setEye2(!eye2)}
                >
                  {eye2 ? (
                    <AiFillEye className="text-2xl text-theme-text" />
                  ) : (
                    <AiFillEyeInvisible className="text-2xl text-theme-text" />
                  )}
                </span>
              </div>
              {formError.confirmPassword && (
                <div className="form-eror">{formError.confirmPassword}</div>
              )}
            </div>
            <div className="flex justify-center gap-5">
              <button
                onClick={() => {
                  handleDetailsChange();
                  setIsLoading(true);
                }}
                type="button"
                className="btn btn-danger"
              >
                Back
              </button>
              <button
                type="button"
                onClick={handleChangePasswordSubmit}
                className="btn btn-primary text-black min-w-[200]"
              >
                {" "}
                Update
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
