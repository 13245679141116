import React, { useEffect, useState } from "react";
import { CgAwards } from "react-icons/cg";
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiFillStar,
  AiFillTwitterSquare,
} from "react-icons/ai";
import { FiLink } from "react-icons/fi";
import { useParams } from "react-router-dom";
import * as api from "../redux/api";
import { toast } from "react-toastify";
import Rating from "react-rating";
import { reactIcons } from "./../utils/icons";
const Profile = () => {
  const [userProfileData, setUserProfileData] = useState(null);

  const { userId } = useParams();
  const getUserData = async (userId) => {
    const { status, data } = await api.getUser(userId);
    if (status >= 200 && status < 300) {
      setUserProfileData(data);
    } else {
      toast.error(data.message);
    }
  };

  useEffect(() => {
    try {
      getUserData(userId);
    } catch (err) {
      console.log(err);
    }
  }, [userId]);

  return (
    <div className=" flex justify-center items-center">
      <div className="shadow-lg relative shadow-theme-lighter bg-white p-14 flex flex-col items-center rounded-3xl max-w-[650px] w-full">
        <div className=" relative h-[100px] w-[100px] lg:w-[150px] lg:h-[150px] rounded-full">
          <img
            className=" rounded-full w-full h-full object-cover drop-shadow-lg  shadow-[0_2px_8px_grey]"
            src={userProfileData?.profileImage || "/images/user.png"}
            alt=""
          />
          <CgAwards className="absolute bottom-0 right-0  text-[45px] text-theme-darker bg-white w-10 h-10 center p-[6px] rounded-full shadow-[0_2px_8px_grey]" />
        </div>
        <div className="mt-10 flex flex-col gap-2 items-center">
          <h5>{userProfileData?.fullname}</h5>
          <div className="flex gap-2 justify-center items-center">
            <Rating
              readonly
              fullSymbol={
                <span className="text-yellow-500 text-[18px] lg:text-[22px]">
                  {reactIcons.fillstar}
                </span>
              }
              emptySymbol={
                <span className="text-[18px] lg:text-[22px]">
                  {reactIcons.star}
                </span>
              }
              fractions={1}
              initialRating={
                userProfileData?.averageRating?._avg?.rating
                  ? userProfileData?.averageRating?._avg?.rating.toFixed(1)
                  : 0
              }
            />
            <span className="font-semibold text-xs lg:text-sm xl:text-base text-black">
              {userProfileData?.averageRating?._avg.rating
                ? userProfileData?.averageRating?._avg.rating?.toFixed(1)
                : 0}
            </span>
          </div>
          <div className="about flex flex-col gap-1 items-center">
            <span className="text-theme-text2 text-xs lg:text-sm xl:text-base">
              Joined Since{" "}
              {new Date(userProfileData?.createdAt).getFullYear() || ""}
            </span>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex gap-6 items-center">
              <div className="w-[90px]">
                <b className="text-xs lg:text-sm xl:text-base">Country:</b>
              </div>
              <div className="text-xs lg:text-sm xl:text-base">
                {userProfileData?.country}
              </div>
            </div>
            {userProfileData?.city && (
              <div className="flex gap-6 items-center">
                <div className="w-[90px]">
                  <b className="text-xs lg:text-sm xl:text-base">City:</b>
                </div>
                <div className="text-xs lg:text-sm xl:text-base">
                  {userProfileData?.city}
                </div>
              </div>
            )}
            {userProfileData?.email && (
              <div className="flex gap-6 items-center">
                <div className="w-[90px]">
                  <b className="text-xs lg:text-sm xl:text-base">Email:</b>
                </div>
                <div className="text-xs lg:text-sm xl:text-base">
                  {userProfileData?.email}
                </div>
              </div>
            )}
            {userProfileData?.meta?.telegram && (
              <div className="flex gap-6 items-center">
                <div className="w-[90px]">
                  <b className="text-xs lg:text-sm xl:text-base">Telegram:</b>
                </div>
                <div className="text-xs lg:text-sm xl:text-base">
                  {userProfileData?.meta?.telegram}
                </div>
              </div>
            )}
            {userProfileData?.meta?.whatsapp && (
              <div className="flex gap-6 items-center">
                <div className="w-[90px]">
                  <b className="text-xs lg:text-sm xl:text-base">Whatsapp:</b>
                </div>
                <div className="text-xs lg:text-sm xl:text-base">
                  {userProfileData?.meta?.whatsapp}
                </div>
              </div>
            )}
            {userProfileData?.mobile && (
              <div className="flex gap-6 items-center">
                <div className="w-[90px]">
                  <b className="text-xs lg:text-sm xl:text-base">Mobile:</b>
                </div>
                <div className="text-xs lg:text-sm xl:text-base">
                  {userProfileData?.mobile}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
