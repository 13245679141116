/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import PropTypes from "prop-types";
import { Fragment } from "react";
import { IoCloseOutline } from "react-icons/io5";
const ImageShow = ({ isOpenImage, closeModalImage, data, notClose }) => {
  const ImageClose = () => {
    closeModalImage();
    notClose();
  };
  return (
    <div>
      <Transition appear show={isOpenImage} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[1000] w-full"
          onClose={ImageClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black-100/60 backdrop-blur-sm" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full relative border border-blue-100 max-w-md transform rounded-2xl bg-black-100  transition-all">
                  <img src={data} alt="" className="w-full" />
                  <button
                    onClick={ImageClose}
                    className="bg-[#D3B25B] text-blue-100 absolute -top-2 -right-2 rounded-md text-22 p-1"
                  >
                    <IoCloseOutline />
                  </button>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
ImageShow.propTypes = {
  isOpenImage: PropTypes.bool.isRequired,
  closeModalImage: PropTypes.func.isRequired,
  data: PropTypes.string.isRequired,
  notClose: PropTypes.func.isRequired,
};
export default ImageShow;
