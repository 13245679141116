import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import "animate.css";
import { Fragment, useEffect, useState } from "react";
import PrivateRoute from "./PrivateRoute";
import { getAuth } from "./redux/features/authSlice";
import { useDispatch } from "react-redux";
import Profile from "./pages/Profile";
import { sideBarLinks } from "./utils/constants";
import NotFound from "./pages/NotFound";
import DashboardLanding from "./pages/DashboardLanding";
import { isLoggedIn } from "./utils/apiHandlers";
function App() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [protectedPath, setProtectedPath] = useState([]);
  useEffect(() => {
    if (sideBarLinks) {
      setProtectedPath(sideBarLinks);
    }
  }, [sideBarLinks]);

  const isLogin = isLoggedIn();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const setAuth = async (e) => {
      try {
        dispatch(getAuth());
      } catch (error) {}
    };
    if (isLogin) {
      setAuth();
    }
  }, [dispatch]);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        limit={1}
        ProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        >
          <Route index path="dashboard" element={<DashboardLanding />}></Route>
          {protectedPath.map(
            (item, index) =>
              item.title !== "Dashboard" && (
                <Route
                  key={index}
                  path={item.path}
                  element={<Fragment>{item.component}</Fragment>}
                ></Route>
              )
          )}
          <Route path="profile/:userId" element={<Profile />}></Route>
          <Route path="*" element={<NotFound />}></Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
