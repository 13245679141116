import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { reactIcons } from "../../utils/icons";
import { toast } from "react-toastify";
import { getAuthData, postAuthData } from "../../utils/apiHandlers";
import { RejectionValidation } from "../../utils/validation";
import { isYupError, parseYupError } from "../../utils/Yup";
import ImageShow from "./ImageShow";
import { Link } from "react-router-dom";

const KycDetails = ({ handleClose, open, kycItem, refresher }) => {
  const [rejectionInput, setRejectionInput] = useState(false);
  const [form, setForm] = useState({});
  const [formError, setFormError] = useState({});
  const handleCloseModal = () => {
    handleClose();
  };
  const [downloadUrl, setDownloadUrl] = useState("");
  const verificationInfo = kycItem?.verificationInfo;
  const handleApproved = async () => {
    try {
      const res = await postAuthData(
        `/user/${kycItem.id}/verification/Verified`
      );
      if (
        res.data.status === "success" ||
        res.status === 200 ||
        res.status === 201
      ) {
        toast.success("KYC request accepted successfully. ");
        refresher();
        handleClose();
      } else {
        toast.error("Error Occurred");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchData = async (id) => {
    try {
      const res = await getAuthData(`/user/verifications/download/${id}`);
      if (res?.status === 200 || res?.status === 201) {
        const url = res?.data?.url;
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.setAttribute("download", `${verificationInfo?.firstname}_kyc.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.success("Downloaded Successfully");
      }
    } catch (error) {
      console.error(error, "DOWNLOAD KYC CSV");
    }
  };

  const handleReject = async () => {
    try {
      setFormError({});
      await RejectionValidation.validate(form, {
        abortEarly: false,
      });
      const res = await postAuthData(
        `/user/${kycItem.id}/verification/Rejected`,
        form
      );
      if (
        res.data.status === "success" ||
        res.status === 200 ||
        res.status === 201
      ) {
        toast.success("KYC request rejected successfully. ");
        refresher();
        handleClose();
        setFormError({});
        setForm({});
      } else {
        toast.error("Error Occurred");
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      }
    }
  };
  const handleRejectOpen = () => {
    setRejectionInput((pre) => !pre);
  };
  const [isOpenImage, setIsOpenImage] = useState(false);
  const [imagePath, setImagePath] = useState("");
  const openImageModal = (e) => {
    setIsOpenImage(true);
    setImagePath(e);
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"md"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle id="scroll-dialog-title" className="relative">
          {" "}
          <h4 className="text-center">KYC Details</h4>
          <h6
            className={`text-center ${
              kycItem?.verificationStatus == "Verified"
                ? "text-green-700"
                : kycItem?.verificationStatus == "Rejected"
                ? "text-red-700"
                : "text-yellow-700"
            }`}
          >
            {kycItem?.verificationStatus}
          </h6>
          <span
            className="absolute top-3 right-3 text-[20px] cursor-pointer"
            onClick={handleClose}
          >
            {reactIcons.close}
          </span>
        </DialogTitle>
        <DialogContent dividers={"paper"}>
          <div className="w-full">
            <div className="mb-5">
              <div className="flex  justify-between">
                <div className="flex items-center gap-3 mb-3 ">
                  <span>{reactIcons.user}</span>
                  <h4 className="text-[18] md:text-[20px] font-medium">
                    Personal Details
                  </h4>
                </div>

                <div
                  onClick={() => fetchData(kycItem.id)}
                  className="text-red-700 text-[25px]"
                >
                  {reactIcons.download}
                </div>
              </div>
              <ul className="details-holder list-inside list-image-[url(/images/correct.png)]">
                <li className="flex pl-7 mb-2">
                  <p className="text-[16px] min-w-[250px]">First Name</p>
                  <p className="text-[16px]">{verificationInfo?.firstname}</p>
                </li>
                <li className="flex pl-7 mb-2">
                  <p className="text-[16px] min-w-[250px]">Last Name</p>
                  <p className="text-[16px]">{verificationInfo?.lastname}</p>
                </li>
                <li className="flex pl-7 mb-2">
                  <p className="text-[16px] min-w-[250px]">Date Of Birth</p>
                  <p className="text-[16px]">
                    {verificationInfo?.dob?.split("T")[0]}
                  </p>
                </li>
                <li className="flex pl-7 mb-2">
                  <p className="text-[16px] min-w-[250px]">Tax Id</p>
                  <p className="text-[16px]">{verificationInfo?.taxId}</p>
                </li>
                <li className="flex pl-7 mb-2">
                  <p className="text-[16px] min-w-[250px]">
                    A/C opening Purpose
                  </p>
                  <p className="text-[16px]">
                    {verificationInfo?.accountOpeningPurpose}
                  </p>
                </li>
              </ul>
            </div>
            <div className="mb-5">
              <div className="flex items-center gap-3 mb-3 ">
                <span>{reactIcons.location}</span>
                <h4 className="text-[18] md:text-[20px] font-medium">
                  Address Details
                </h4>
              </div>
              <ul className="details-holder list-inside list-image-[url(/images/correct.png)]">
                <li className="flex pl-7 mb-2">
                  <p className="text-[16px] min-w-[250px]">Country</p>
                  <p className="text-[16px]">
                    {verificationInfo?.address?.country}
                  </p>
                </li>
                <li className="flex pl-7 mb-2">
                  <p className="text-[16px] min-w-[250px]">State</p>
                  <p className="text-[16px]">
                    {verificationInfo?.address?.state}
                  </p>
                </li>
                <li className="flex pl-7 mb-2">
                  <p className="text-[16px] min-w-[250px]">City</p>
                  <p className="text-[16px]">
                    {verificationInfo?.address?.city}
                  </p>
                </li>
                <li className="flex pl-7 mb-2">
                  <p className="text-[16px] min-w-[250px]">Street</p>
                  <p className="text-[16px]">
                    {verificationInfo?.address?.street}
                  </p>
                </li>
                <li className="flex pl-7 mb-2">
                  <p className="text-[16px] min-w-[250px]">Postal Code</p>
                  <p className="text-[16px]">
                    {verificationInfo?.address?.postalCode}
                  </p>
                </li>
                {kycItem?.verificationType !== "Individual" && (
                  <li className="flex pl-7 mb-2">
                    <p className="text-[16px] min-w-[250px]">
                      Country Of Incorporation
                    </p>
                    <p className="text-[16px]">
                      {verificationInfo?.address?.countryOfIncorporation}
                    </p>
                  </li>
                )}
              </ul>
            </div>
            {kycItem?.verificationType !== "Individual" && (
              <div className="mb-5">
                <div className="flex items-center gap-3 mb-3 ">
                  <span>{reactIcons.user}</span>
                  <h4 className="text-[18] md:text-[20px] font-medium">
                    Company Details
                  </h4>
                </div>
                <ul className="details-holder list-inside list-image-[url(/images/correct.png)]">
                  <li className="flex pl-7 mb-2">
                    <p className="text-[16px] min-w-[250px]">Company name</p>
                    <p className="text-[16px]">
                      {verificationInfo?.company?.name}
                    </p>
                  </li>
                  <li className="flex pl-7 mb-2">
                    <p className="text-[16px] min-w-[250px]">Role</p>
                    <p className="text-[16px]">
                      {verificationInfo?.company?.role}
                    </p>
                  </li>
                  <li className="flex pl-7 mb-2">
                    <p className="text-[16px] min-w-[250px]">
                      Registration Number
                    </p>
                    <p className="text-[16px]">
                      {verificationInfo?.company?.registrationNumber}
                    </p>
                  </li>
                </ul>
              </div>
            )}

            <div className="mb-5">
              <div className="flex items-center gap-3 mb-3 ">
                <span>{reactIcons.document}</span>
                <h4 className="text-[18] md:text-[20px] font-medium">
                  Documents Details
                </h4>
              </div>
              <ul className="details-holder list-inside list-image-[url(/images/correct.png)]">
                {kycItem?.verificationType === "Individual" ? (
                  <>
                    {" "}
                    <li className="flex pl-7 mb-2">
                      <p className="text-[16px] min-w-[250px]">Address Proof</p>{" "}
                      <img
                        src={
                          verificationInfo?.document?.addressProof
                            ? verificationInfo?.document?.addressProof
                            : "/images/no-img.jpg"
                        }
                        className="w-[75px] h-[75px] border"
                        alt="address proof"
                        onClick={() =>
                          openImageModal(
                            verificationInfo?.document?.addressProof
                          )
                        }
                      />
                    </li>
                    <li className="flex pl-7 mb-2">
                      <p className="text-[16px] min-w-[250px]">
                        Govt. Identity Proof
                      </p>
                      <img
                        src={
                          verificationInfo?.document?.governmentId
                            ? verificationInfo?.document?.governmentId
                            : "/images/no-img.jpg"
                        }
                        className="w-[75px] h-[75px] border"
                        alt="address proof"
                        onClick={() =>
                          openImageModal(
                            verificationInfo?.document?.governmentId
                          )
                        }
                      />{" "}
                    </li>{" "}
                  </>
                ) : (
                  <>
                    <li className="flex pl-7 mb-2">
                      <p className="text-[16px] min-w-[250px]">
                        Company Registration
                      </p>
                      <img
                        src={
                          verificationInfo?.document?.companyRegistrationProof
                            ? verificationInfo?.document
                                ?.companyRegistrationProof
                            : "/images/no-img.jpg"
                        }
                        className="w-[75px] h-[75px] border"
                        alt="address proof"
                        onClick={() =>
                          openImageModal(
                            verificationInfo?.document?.companyRegistrationProof
                          )
                        }
                      />{" "}
                    </li>
                    <li className="flex pl-7 mb-2">
                      <p className="text-[16px] min-w-[250px]">
                        TAX Compliance
                      </p>
                      <img
                        src={
                          verificationInfo?.document?.memorandumOfAssociation
                            ? verificationInfo?.document
                                ?.memorandumOfAssociation
                            : "/images/no-img.jpg"
                        }
                        className="w-[75px] h-[75px] border"
                        alt="address proof"
                        onClick={() =>
                          openImageModal(
                            verificationInfo?.document?.memorandumOfAssociation
                          )
                        }
                      />{" "}
                    </li>{" "}
                  </>
                )}
                {kycItem?.verificationStatus !== "Verified" &&
                  kycItem?.verificationStatus !== "Pending" && (
                    <li className="flex pl-7 mt-4 mb-2">
                      <p className="text-[16px] text-red-700 min-w-[250px]">
                        Rejection Reason
                      </p>
                      <p className="text-[16px]">
                        {kycItem?.verificationRejectionReason}
                      </p>
                    </li>
                  )}
              </ul>
            </div>
          </div>
        </DialogContent>
        {kycItem.verificationStatus === "Pending" && !rejectionInput && (
          <DialogActions>
            <button
              type="button"
              onClick={handleApproved}
              className="btn btn-primary min-w-[200]"
            >
              Approve
            </button>
            <button
              onClick={handleRejectOpen}
              type="button"
              className="btn btn-danger"
            >
              Reject
            </button>
          </DialogActions>
        )}
        {rejectionInput && kycItem.verificationStatus !== "Rejected" && (
          <div className="flex justify-between p-3">
            <div className="w-full">
              <input
                type="text"
                placeholder="Rejection Reason"
                name="rejectionReason"
                className="w-full"
                onChange={(e) => {
                  setFormError({ ...formError, rejectionReason: "" });
                  setForm({ ...form, rejectionReason: e.target.value });
                }}
                value={form.rejectionReason}
              />
              {formError.rejectionReason && (
                <div className="form-eror">{formError.rejectionReason}</div>
              )}
            </div>
            <button
              onClick={handleReject}
              type="button"
              className="btn mt-1 mx-3 btn-danger"
            >
              Submit
            </button>
            <button
              onClick={handleRejectOpen}
              type="button"
              className="btn btn-primary"
            >
              Cancel
            </button>
          </div>
        )}
      </Dialog>
      <ImageShow
        isOpenImage={isOpenImage}
        closeModalImage={() => setIsOpenImage(false)}
        data={imagePath}
        notClose={handleClose}
      />
    </>
  );
};

export default KycDetails;
