import React, { useEffect, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../redux/features/authSlice";
import { loginValidation } from "./../utils/validation";
import { isYupError, parseYupError } from "./../utils/Yup";
import { toast } from "react-toastify";
import { isLoggedIn } from "../utils/apiHandlers";

const Login = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const [eye, setEye] = useState(false);
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [formError, setFormError] = useState({});
  const navigate = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setFormError({});
      await loginValidation.validate(form, {
        abortEarly: false,
      });
      dispatch(login({ form, navigate, toast }));
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
    setFormError({
      ...formError,
      [name]: "",
    });
  };

  useEffect(() => {
    const login = isLoggedIn();
    if (login) {
      navigate("/dashboard");
    }
  });

  return (
    <div className=" min-h-screen w-full flex justify-center bg-opacity-50 bg-blue-50 items-center">
      <div className="shadow-[rgba(7,_65,_210,_0.1)_0px_9px_30px]  bg-white p-14 flex flex-col items-center rounded-xl max-w-[550px] w-full">
        <h2 className="text-theme-darker text-center  font-black">
          Welcome Back
        </h2>
        <span className="text-[18px] mt-2 font-medium text-theme-text ">
          Sign Into Admin Account
        </span>

        <form className="w-full flex flex-col gap-6 mt-4">
          <div className="input_field mt-3">
            <label htmlFor="">Email</label>
            <input
              type="text"
              placeholder="Enter Email Address"
              name="email"
              value={form.email}
              onChange={(e) => {
                setForm({ email: e.target.value });
                setFormError({ ...formError, [e.target.name]: "" });
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  handleLogin();
                }
              }}
              className="mt-2"
            />
            {formError.email && (
              <div className="form-eror">{formError.email}</div>
            )}
            <div>
              <div className="input_field relative mt-3">
                <label htmlFor="">Password</label>
                <input
                  type={eye ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  onChange={handleChange}
                  className="mt-2"
                  value={form.password}
                  onKeyDown={(e) => {
                    if (e.code === "Enter") {
                      handleLogin();
                    }
                  }}
                />

                <span
                  className="absolute right-4 top-[50px] translate-y-[-50%] cursor-pointer z-10"
                  onClick={() => setEye(!eye)}
                >
                  {eye ? (
                    <AiFillEye className="text-2xl text-theme-text" />
                  ) : (
                    <AiFillEyeInvisible className="text-2xl text-theme-text" />
                  )}
                </span>
              </div>
              {formError.password && (
                <div className="form-eror">{formError.password}</div>
              )}
            </div>
          </div>
          <button
            onClick={handleLogin}
            type="submit"
            className="btn btn-primary w-full max-w-[100%] px-2 uppercase  text-[18px] "
          >
            {loading ? "Loading.." : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
