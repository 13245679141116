import { Collapse } from "@mui/material";
import React, { useEffect, useState } from "react";
import Spinner from "../components/loaders/Spinner";
import VentursaTokanExchange from "../contracts/VentursaTokanExchange.json";
import ERC20 from "../contracts/ERC20.json";
import { contracts } from "../utils/env";
import { exploreUrl } from "../utils/env";
import { toast } from "react-toastify";
import { reactIcons } from "./../utils/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";

let temp = true;
const initialShowExchange = {
  baseToken: false,
  changeExchangeRate: false,
  changeExchangeToken: false,
  changeInterestRate: false,
  withdrawBaseTokens: false,
  withdrawExchangeTokens: false,
};
const Setting = () => {
  const [eyesToggle, setEyesToggle] = useState({
    airdropBalFxf: false,
    exchangeBalFxf: false,
    exchangeFxfAdd: false,
    exchangeRate: false,
    exchangeBalUSDT: false,
    exchangeIntRate: false,
    exchangeMRA: false,
    exchangeUSDTAdd: false,
    partnersBal: false,
  });
  const [switchToggle, setSwitchToggle] = useState({
    airdropBalFxf: false,
    partnersBal: false,
    exchangeBalFxf: false,
    exchangeFxfAdd: false,
    exchangeRate: false,
    exchangeBalUSDT: false,
    exchangeIntRate: false,
    exchangeMRA: false,
    exchangeUSDTAdd: false,
  });
  const handleSwitchChange = (name) => {
    setSwitchToggle({ ...switchToggle, [name]: !switchToggle[name] });
  };
  const [isLoading, setIsLoading] = useState(false);
  const [partnersBal, setPartnersBal] = useState(null);
  const [showExchangeToken, setShowExchangeToken] =
    useState(initialShowExchange);
  const [tokenValue, setTokenValue] = useState("0");
  const [exchangeDetails, setExchangeDetails] = useState({});
  const web3 = window.web3;
  const ethers = window.ethers;

  const getPartnersInfoBal = async () => {
    const contract = new window.web3.eth.Contract(
      ERC20,
      contracts[process.env.REACT_APP_NETWORK].ventursaToken
    );
    const balance = await contract.methods
      .balanceOf(contracts[process.env.REACT_APP_NETWORK].owner)
      .call()
      .catch((error) => {
        console.log(error);
      });
    setPartnersBal(Number(web3.utils.fromWei(balance)).toFixed(2));
  };

  const getExchangeDetails = async () => {
    const contract = new window.web3.eth.Contract(
      VentursaTokanExchange,
      contracts[process.env.REACT_APP_NETWORK].ventursaTokenExchange
    );

    const exchangeRateValue = await contract.methods
      .exchangeRate()
      .call()
      .catch((error) => {
        console.log(error);
      });
    setExchangeDetails((prev) => {
      return {
        ...prev,
        exchangeRateValue: Number(
          ethers?.utils?.formatUnits(exchangeRateValue || 0, 6)
        ).toFixed(4),
      };
    });
    const balance = await contract.methods
      .balanceOfBaseToken()
      .call()
      .catch((error) => {
        console.log(error);
      });
    setExchangeDetails((prev) => {
      return {
        ...prev,
        exchangeBalanceOfToken: Number(
          ethers.utils.formatUnits(balance || 0, 18)
        ).toFixed(4),
      };
    });
    const address = await contract.methods
      .baseToken()
      .call()
      .catch((error) => {
        console.log(error);
      });
    setExchangeDetails((prev) => {
      return {
        ...prev,
        exchangeBaseToken: address,
      };
    });
    const balanceOfExchangeTokenAddress = await contract.methods
      .balanceOfExchangeToken()
      .call()
      .catch((error) => {
        console.log(error);
      });
    setExchangeDetails((prev) => {
      return {
        ...prev,
        balanceOfExchangeToken: Number(
          ethers.utils.formatUnits(balanceOfExchangeTokenAddress || 0, 6)
        ).toFixed(4),
      };
    });
    const minExchangeAmount = await contract.methods
      .minExchangeAmount()
      .call()
      .catch((error) => {
        console.log(error);
      });
    setExchangeDetails((prev) => {
      return {
        ...prev,
        minExchangeAmount: Number(
          web3.utils.fromWei(minExchangeAmount || "0")
        ).toFixed(4),
      };
    });
    const exchangeTokenAddress = await contract.methods
      .exchangeToken()
      .call()
      .catch((error) => {
        console.log(error);
      });
    setExchangeDetails((prev) => {
      return {
        ...prev,
        exchangeTokenAddress: exchangeTokenAddress,
      };
    });
    const exchangeInterestRate = await contract.methods
      .exchangeFees()
      .call()
      .catch((error) => {
        console.log(error);
      });
    setExchangeDetails((prev) => {
      return {
        ...prev,
        interestRate: Number(
          ethers.utils.formatUnits(exchangeInterestRate || 0, 6)
        ).toFixed(4),
      };
    });
  };

  const enableEthereum = async () => {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    window.web3 = new window.Web3(window.ethereum);
    return accounts[0];
  };

  useEffect(() => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      getExchangeDetails();
      getPartnersInfoBal();
    } else if (temp) {
      toast.error("Please install Meta Mask");
      temp = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddExchangeToken = async (
    methodName,
    isTokenExchnage = false
  ) => {
    if (methodName === "withdrawExchangeTokens") {
      if (exchangeDetails.balanceOfExchangeToken < Number(tokenValue)) {
        toast.error("Insufficient Balance");
        return;
      }
    }
    if (methodName === "withdrawBaseTokens") {
      if (exchangeDetails.exchangeBalanceOfToken < Number(tokenValue)) {
        toast.error("Insufficient Balance");
        return;
      }
    }
    if (tokenValue < 1 && isTokenExchnage) {
      toast.error("Please enter token value");
      return false;
    }
    setIsLoading(true);
    const account = await enableEthereum();
    if (
      window.web3.utils.toChecksumAddress(account) !==
      window.web3.utils.toChecksumAddress(
        contracts[process.env.REACT_APP_NETWORK].owner
      )
    ) {
      toast.error("please connect correct wallet");
      setIsLoading(false);
      return;
    }
    const contract = new window.web3.eth.Contract(
      VentursaTokanExchange,
      contracts[process.env.REACT_APP_NETWORK].ventursaTokenExchange
    );

    //dynamic  methord call
    let res;
    if (isTokenExchnage) {
      res = await contract.methods[methodName](
        account,
        ethers.utils.parseUnits(tokenValue, 18)
      )
        .send({ from: account })
        .catch((error) => error);
    } else {
      res = await contract.methods[methodName](
        ethers.utils.parseUnits(tokenValue, 18)
      )
        .send({ from: account })
        .catch((error) => error);
    }

    if (res?.message) {
      toast.error(res.message);
    } else {
      toast.success("Exchange Token added succesfully");
      setShowExchangeToken(initialShowExchange);
      getExchangeDetails();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setTokenValue("0");
  }, [showExchangeToken]);
  return (
    <>
      {isLoading && <Spinner />}
      <div className="grid grid-cols-1 gap-8 settings">
        <div>
          <div className="flex gap-6 items-center mb-4">
            <h4>Exchange</h4>
            <div className="flex gap-1 items-center">
              <span className="text-xs lg:text-sm font-semibold">
                {" "}
                {contracts[
                  process.env.REACT_APP_NETWORK
                ].ventursaTokenExchange.slice(0, 4) +
                  "..." +
                  contracts[
                    process.env.REACT_APP_NETWORK
                  ].ventursaTokenExchange.slice(-4)}
              </span>
              <CopyToClipboard
                text={
                  contracts[process.env.REACT_APP_NETWORK].ventursaTokenExchange
                }
                onCopy={() => toast.success("Copied SuccessFully")}
              >
                <span className="mx-1 text-xl text-zinc-400 cursor-pointer w-10 h-10 rounded-lg flex-center hover:bg-zinc-100">
                  {reactIcons.copy}
                </span>
              </CopyToClipboard>
              <a
                className="underline hover:text-blue-500 text-blue-400 font-semibold text-xs  "
                href={`${exploreUrl}/address/${
                  contracts[process.env.REACT_APP_NETWORK].ventursaTokenExchange
                }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View On Explorer
              </a>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-4">
            <div className="p-3 py-5 rounded-lg border border-zinc-200 ">
              <h5 className="mb-6 text-black/80">Token Addresses</h5>
              <div className="space-y-6">
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4 justify-between items-center">
                    <div className="flex gap-6 items-center">
                      <div className="flex gap-2 items-center w-[180px] lg:w-[200px] xl:w-[230px] flex-shrink-0">
                        <span
                          onClick={() =>
                            setEyesToggle({
                              ...eyesToggle,
                              exchangeFxfAdd: !eyesToggle.exchangeFxfAdd,
                            })
                          }
                          role="button"
                          className="text-zinc-400 text-xl lg:text-[22px] "
                        >
                          {eyesToggle.exchangeFxfAdd
                            ? reactIcons.eyeVisible
                            : reactIcons.eyeInVisible}
                        </span>
                        <b className="text-xs lg:text-sm xl:text-base">
                          Ventursa Address
                        </b>
                      </div>
                      <div>
                        {eyesToggle.exchangeFxfAdd ? (
                          exchangeDetails?.exchangeBaseToken ? (
                            <div className="flex gap-1 items-center">
                              <span className="text-xs lg:text-sm font-semibold">
                                {" "}
                                {exchangeDetails?.exchangeBaseToken.slice(
                                  0,
                                  4
                                ) +
                                  "..." +
                                  exchangeDetails?.exchangeBaseToken.slice(-4)}
                              </span>
                              <CopyToClipboard
                                text={exchangeDetails?.exchangeBaseToken}
                                onCopy={() =>
                                  toast.success("Copied SuccessFully")
                                }
                              >
                                <span className="mx-1 text-xl text-zinc-400 cursor-pointer w-10 h-10 rounded-lg flex-center hover:bg-zinc-100">
                                  {reactIcons.copy}
                                </span>
                              </CopyToClipboard>
                              <a
                                className="underline hover:text-blue-500 text-blue-400 font-semibold text-xs  "
                                href={`https://mumbai.polygonscan.com/address/${exchangeDetails?.exchangeBaseToken}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View On Explorer
                              </a>
                            </div>
                          ) : (
                            <div className="text-xs lg:text-sm xl:text-base">
                              Not Available
                            </div>
                          )
                        ) : (
                          <div className="h-4 bg-zinc-200 w-[120px] lg:w-[160px] xl:w-[200px]" />
                        )}
                      </div>
                    </div>
                    <div className="flex gap-2 items-center">
                      <div
                        onClick={() => handleSwitchChange("exchangeFxfAdd")}
                        className="text-xs xl:text-sm font-semibold cursor-pointer gap-1 flex-center"
                      >
                        <span className="flex-shrink-0 text-black/80">
                          Read More{" "}
                        </span>
                        <span className="text-2xl">
                          {switchToggle.exchangeFxfAdd
                            ? reactIcons.arrowUp
                            : reactIcons.arrowDown}
                        </span>
                      </div>
                    </div>
                  </div>

                  <Collapse in={switchToggle.exchangeFxfAdd}>
                    <p className="text-sm font-semibold text-zinc-600">
                      Ventursa Exchange Address
                    </p>
                  </Collapse>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4 justify-between items-center">
                    <div className="flex gap-6 items-center">
                      <div className="flex gap-2 items-center w-[180px] lg:w-[200px] xl:w-[230px] flex-shrink-0">
                        <span
                          onClick={() =>
                            setEyesToggle({
                              ...eyesToggle,
                              exchangeUSDTAdd: !eyesToggle.exchangeUSDTAdd,
                            })
                          }
                          role="button"
                          className="text-zinc-400 text-xl lg:text-[22px] "
                        >
                          {eyesToggle.exchangeUSDTAdd
                            ? reactIcons.eyeVisible
                            : reactIcons.eyeInVisible}
                        </span>
                        <b className="text-xs lg:text-sm xl:text-base">
                          USDT Address
                        </b>
                      </div>

                      <div>
                        {" "}
                        {eyesToggle.exchangeUSDTAdd ? (
                          exchangeDetails?.exchangeTokenAddress ? (
                            <div className="flex gap-1 items-center">
                              <span className="text-xs lg:text-sm font-semibold">
                                {" "}
                                {exchangeDetails?.exchangeTokenAddress.slice(
                                  0,
                                  4
                                ) +
                                  "..." +
                                  exchangeDetails?.exchangeTokenAddress.slice(
                                    -4
                                  )}
                              </span>
                              <CopyToClipboard
                                text={exchangeDetails?.exchangeTokenAddress}
                                onCopy={() =>
                                  toast.success("Copied SuccessFully")
                                }
                              >
                                <span className="mx-1 text-xl text-zinc-400 cursor-pointer w-10 h-10 rounded-lg flex-center hover:bg-zinc-100">
                                  {reactIcons.copy}
                                </span>
                              </CopyToClipboard>
                              <a
                                className="underline hover:text-blue-500 text-blue-400 font-semibold text-xs  "
                                href={`https://mumbai.polygonscan.com/address/${exchangeDetails?.exchangeTokenAddress}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View On Explorer
                              </a>
                            </div>
                          ) : (
                            <div className="text-xs lg:text-sm xl:text-base">
                              Not Available
                            </div>
                          )
                        ) : (
                          <div className="h-4 bg-zinc-200 w-[120px] lg:w-[160px] xl:w-[200px]" />
                        )}
                      </div>
                      {showExchangeToken?.changeExchangeToken ? (
                        <div className="flex gap-6 items-center">
                          <input
                            type="number"
                            value={tokenValue}
                            onChange={(e) => setTokenValue(e.target.value)}
                            placeholder="0"
                          />
                          <button
                            onClick={() =>
                              handleAddExchangeToken(
                                "changeExchangeToken",
                                false
                              )
                            }
                            className="btn btn-sm-width btn-sm btn-primary flex-shrink-0"
                          >
                            Change
                          </button>
                          <button
                            onClick={() =>
                              setShowExchangeToken({
                                ...initialShowExchange,
                                changeExchangeToken: false,
                              })
                            }
                            className="btn btn-sm btn-danger"
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <span
                          onClick={() =>
                            setShowExchangeToken({
                              ...initialShowExchange,
                              changeExchangeToken: true,
                            })
                          }
                          className="btn btn-sm btn-primary"
                        >
                          Change Exchange Token
                        </span>
                      )}
                    </div>
                    <div className="flex gap-2 items-center">
                      <div
                        onClick={() => handleSwitchChange("exchangeUSDTAdd")}
                        className="text-xs xl:text-sm font-semibold cursor-pointer gap-1 flex-center"
                      >
                        <span className="flex-shrink-0 text-black/80">
                          Read More{" "}
                        </span>
                        <span className="text-2xl">
                          {switchToggle.exchangeUSDTAdd
                            ? reactIcons.arrowUp
                            : reactIcons.arrowDown}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Collapse in={switchToggle.exchangeUSDTAdd}>
                    {" "}
                    <p className="text-sm font-semibold text-zinc-600">
                      USDT Address
                    </p>
                  </Collapse>
                </div>
              </div>
            </div>
            <div className="p-3 py-5 rounded-lg border border-zinc-200 ">
              <h5 className="mb-6 text-black/80">Token Balances</h5>
              <div className="space-y-6">
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4 justify-between items-center">
                    <div className="flex gap-6 items-center">
                      <div className="flex gap-2 items-center w-[180px] lg:w-[200px] xl:w-[230px] flex-shrink-0">
                        <span
                          onClick={() =>
                            setEyesToggle({
                              ...eyesToggle,
                              exchangeBalFxf: !eyesToggle.exchangeBalFxf,
                            })
                          }
                          role="button"
                          className="text-zinc-400 text-xl lg:text-[22px] "
                        >
                          {eyesToggle.exchangeBalFxf
                            ? reactIcons.eyeVisible
                            : reactIcons.eyeInVisible}
                        </span>
                        <b className="text-xs lg:text-sm xl:text-base">
                          Balance of Ventursa
                        </b>
                      </div>
                      <div className="min-w-[120px] lg:min-w-[160px] xl:min-w-[200px]">
                        {" "}
                        {eyesToggle.exchangeBalFxf ? (
                          exchangeDetails?.exchangeBalanceOfToken ? (
                            exchangeDetails?.exchangeBalanceOfToken
                          ) : (
                            <div className="text-xs lg:text-sm xl:text-base">
                              Not Available
                            </div>
                          )
                        ) : (
                          <div className="h-4 bg-zinc-200 w-[120px] lg:w-[160px] xl:w-[200px]" />
                        )}
                      </div>
                      {showExchangeToken?.addBaseTokens ? (
                        <div className="flex gap-2 items-center">
                          <input
                            type="number"
                            value={tokenValue}
                            onChange={(e) => setTokenValue(e.target.value)}
                            placeholder="0"
                          />
                          <button
                            onClick={() =>
                              handleAddExchangeToken("addBaseTokens", true)
                            }
                            className="btn btn-sm-width btn-sm btn-primary flex-shrink-0"
                          >
                            Add
                          </button>
                          <button
                            onClick={() =>
                              setShowExchangeToken({
                                ...initialShowExchange,
                                showExchangeToken: false,
                              })
                            }
                            className="btn btn-sm-width btn-sm btn-danger"
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <span
                          onClick={() =>
                            setShowExchangeToken({
                              ...initialShowExchange,
                              addBaseTokens: true,
                            })
                          }
                          className="btn btn-sm-width btn-sm btn-primary"
                        >
                          Add
                        </span>
                      )}
                      {showExchangeToken?.withdrawBaseTokens ? (
                        <div className="flex gap-6 items-center">
                          <input
                            type="number"
                            value={tokenValue}
                            onChange={(e) => setTokenValue(e.target.value)}
                            placeholder="0"
                          />
                          <button
                            onClick={() =>
                              handleAddExchangeToken("withdrawBaseTokens", true)
                            }
                            className="btn btn-sm-width btn-sm btn-primary flex-shrink-0"
                          >
                            Withdraw
                          </button>
                          <button
                            onClick={() =>
                              setShowExchangeToken({
                                ...initialShowExchange,
                                withdrawBaseTokens: false,
                              })
                            }
                            className="btn btn-sm-width btn-sm btn-danger"
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <span
                          onClick={() =>
                            setShowExchangeToken({
                              ...initialShowExchange,
                              withdrawBaseTokens: true,
                            })
                          }
                          className="btn btn-sm-width btn-sm btn-primary"
                        >
                          Withdraw
                        </span>
                      )}
                    </div>
                    <div className="flex gap-2 items-center">
                      <div
                        onClick={() => handleSwitchChange("exchangeBalFxf")}
                        className="text-xs xl:text-sm font-semibold cursor-pointer gap-1 flex-center"
                      >
                        <span className="flex-shrink-0 text-black/80">
                          Read More{" "}
                        </span>
                        <span className="text-2xl">
                          {switchToggle.exchangeBalFxf
                            ? reactIcons.arrowUp
                            : reactIcons.arrowDown}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Collapse in={switchToggle.exchangeBalFxf}>
                    <p className="text-sm font-semibold text-zinc-600">
                      Add Ventursa Exchange Balance
                    </p>
                  </Collapse>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4 justify-between items-center">
                    <div className="flex gap-6 items-center">
                      <div className="flex gap-2 items-center w-[180px] lg:w-[200px] xl:w-[230px] flex-shrink-0">
                        <span
                          onClick={() =>
                            setEyesToggle({
                              ...eyesToggle,
                              exchangeBalUSDT: !eyesToggle.exchangeBalUSDT,
                            })
                          }
                          role="button"
                          className="text-zinc-400 text-xl lg:text-[22px] "
                        >
                          {eyesToggle.exchangeBalUSDT
                            ? reactIcons.eyeVisible
                            : reactIcons.eyeInVisible}
                        </span>
                        <b className="text-xs lg:text-sm xl:text-base">
                          Balance Of USDT
                        </b>
                      </div>
                      <div className="min-w-[120px] lg:min-w-[160px] xl:min-w-[200px]">
                        {" "}
                        {eyesToggle.exchangeBalUSDT ? (
                          exchangeDetails?.balanceOfExchangeToken ? (
                            exchangeDetails?.balanceOfExchangeToken
                          ) : (
                            <div className="text-xs lg:text-sm xl:text-base">
                              Not Available
                            </div>
                          )
                        ) : (
                          <div className="h-4 bg-zinc-200 w-[120px] lg:w-[160px] xl:w-[200px]" />
                        )}
                      </div>

                      {showExchangeToken?.withdrawExchangeTokens ? (
                        <div className="flex gap-6 items-center">
                          <input
                            type="number"
                            value={tokenValue}
                            onChange={(e) => setTokenValue(e.target.value)}
                            placeholder="0"
                          />
                          <button
                            onClick={() =>
                              handleAddExchangeToken(
                                "withdrawExchangeTokens",
                                true
                              )
                            }
                            className="btn btn-sm btn-primary flex-shrink-0"
                          >
                            Withdraw
                          </button>
                          <button
                            onClick={() =>
                              setShowExchangeToken({
                                ...initialShowExchange,
                                withdrawExchangeTokens: false,
                              })
                            }
                            className="btn btn-sm btn-danger"
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <span
                          onClick={() =>
                            setShowExchangeToken({
                              ...initialShowExchange,
                              withdrawExchangeTokens: true,
                            })
                          }
                          className="btn btn-sm btn-primary"
                        >
                          Withdraw
                        </span>
                      )}
                    </div>
                    <div className="flex gap-2 items-center">
                      <div
                        onClick={() => handleSwitchChange("exchangeBalUSDT")}
                        className="text-xs xl:text-sm font-semibold cursor-pointer gap-1 flex-center"
                      >
                        <span className="flex-shrink-0 text-black/80">
                          Read More{" "}
                        </span>
                        <span className="text-2xl">
                          {switchToggle.exchangeBalUSDT
                            ? reactIcons.arrowUp
                            : reactIcons.arrowDown}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Collapse in={switchToggle.exchangeBalUSDT}>
                    {" "}
                    <p className="text-sm font-semibold text-zinc-600">
                      USDT Balance
                    </p>
                  </Collapse>
                </div>
              </div>
            </div>
            <div className="p-3 py-5 rounded-lg border border-zinc-200 ">
              <h5 className="mb-6 text-black/80">Configuration</h5>
              <div className="space-y-6">
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4 justify-between items-center">
                    <div className="flex gap-6 items-center">
                      <div className="flex gap-2 items-center w-[180px] lg:w-[200px] xl:w-[230px] flex-shrink-0">
                        <span
                          onClick={() =>
                            setEyesToggle({
                              ...eyesToggle,
                              exchangeRate: !eyesToggle.exchangeRate,
                            })
                          }
                          role="button"
                          className="text-zinc-400 text-xl lg:text-[22px] "
                        >
                          {eyesToggle.exchangeRate
                            ? reactIcons.eyeVisible
                            : reactIcons.eyeInVisible}
                        </span>
                        <b className="text-xs lg:text-sm xl:text-base">
                          Exchange Rate
                        </b>
                      </div>
                      <div className="min-w-[120px] lg:min-w-[160px] xl:min-w-[200px]">
                        {eyesToggle.exchangeRate ? (
                          exchangeDetails?.exchangeRateValue ? (
                            exchangeDetails?.exchangeRateValue
                          ) : (
                            <div className="text-xs lg:text-sm xl:text-base">
                              Not Available
                            </div>
                          )
                        ) : (
                          <div className="h-4 bg-zinc-200 w-[120px] lg:w-[160px] xl:w-[200px]" />
                        )}
                      </div>
                      {showExchangeToken?.changeExchangeRate ? (
                        <div className="flex gap-6 items-center">
                          <input
                            type="number"
                            value={tokenValue}
                            onChange={(e) => setTokenValue(e.target.value)}
                            placeholder="0"
                          />
                          <button
                            onClick={() =>
                              handleAddExchangeToken(
                                "changeExchangeRate",
                                false
                              )
                            }
                            className="btn btn-sm-width btn-sm btn-primary flex-shrink-0"
                          >
                            Change
                          </button>
                          <button
                            onClick={() =>
                              setShowExchangeToken({
                                ...initialShowExchange,
                                changeExchangeRate: false,
                              })
                            }
                            className="btn btn-sm btn-danger"
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <span
                          onClick={() =>
                            setShowExchangeToken({
                              ...initialShowExchange,
                              changeExchangeRate: true,
                            })
                          }
                          className="btn btn-lg-width btn-sm btn-primary"
                        >
                          Change Exchange Rate
                        </span>
                      )}
                    </div>
                    <div className="flex gap-2 items-center">
                      <div
                        onClick={() => handleSwitchChange("exchangeRate")}
                        className="text-xs xl:text-sm font-semibold cursor-pointer gap-1 flex-center"
                      >
                        <span className="flex-shrink-0 text-black/80">
                          Read More{" "}
                        </span>
                        <span className="text-2xl">
                          {switchToggle.exchangeRate
                            ? reactIcons.arrowUp
                            : reactIcons.arrowDown}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Collapse in={switchToggle.exchangeRate}>
                    {" "}
                    <p className="text-sm font-semibold text-zinc-600">
                      Exhange Rate 1 = exchange rate * USDT
                    </p>
                  </Collapse>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4 justify-between items-center">
                    <div className="flex gap-6 items-center">
                      <div className="flex gap-2 items-center w-[180px] lg:w-[200px] xl:w-[230px] flex-shrink-0">
                        <span
                          onClick={() =>
                            setEyesToggle({
                              ...eyesToggle,
                              exchangeIntRate: !eyesToggle.exchangeIntRate,
                            })
                          }
                          role="button"
                          className="text-zinc-400 text-xl lg:text-[22px] "
                        >
                          {eyesToggle.exchangeIntRate
                            ? reactIcons.eyeVisible
                            : reactIcons.eyeInVisible}
                        </span>
                        <b className="text-xs lg:text-sm xl:text-base">
                          Commission Rate
                        </b>
                      </div>
                      <div className="min-w-[120px] lg:min-w-[160px] xl:min-w-[200px]">
                        {" "}
                        {eyesToggle.exchangeIntRate ? (
                          exchangeDetails?.interestRate ? (
                            exchangeDetails?.interestRate
                          ) : (
                            <div className="text-xs lg:text-sm xl:text-base">
                              Not Available
                            </div>
                          )
                        ) : (
                          <div className="h-4 bg-zinc-200 w-[120px] lg:w-[160px] xl:w-[200px]" />
                        )}
                      </div>
                      {showExchangeToken?.changeInterestRate ? (
                        <div className="flex gap-6 items-center">
                          <input
                            type="number"
                            value={tokenValue}
                            onChange={(e) => setTokenValue(e.target.value)}
                            placeholder="0"
                          />
                          <button
                            onClick={() =>
                              handleAddExchangeToken(
                                "changeInterestRate",
                                false
                              )
                            }
                            className="btn btn-sm-width btn-sm btn-primary flex-shrink-0"
                          >
                            Change
                          </button>
                          <button
                            onClick={() =>
                              setShowExchangeToken({
                                ...initialShowExchange,
                                changeInterestRate: false,
                              })
                            }
                            className="btn btn-sm btn-danger"
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <span
                          onClick={() =>
                            setShowExchangeToken({
                              ...initialShowExchange,
                              changeInterestRate: true,
                            })
                          }
                          className="btn btn-lg-width btn-sm btn-primary"
                        >
                          Change Commission Rate
                        </span>
                      )}
                    </div>
                    <div className="flex gap-2 items-center">
                      <div
                        onClick={() => handleSwitchChange("exchangeIntRate")}
                        className="text-xs xl:text-sm font-semibold cursor-pointer gap-1 flex-center"
                      >
                        <span className="flex-shrink-0 text-black/80">
                          Read More{" "}
                        </span>
                        <span className="text-2xl">
                          {switchToggle.exchangeIntRate
                            ? reactIcons.arrowUp
                            : reactIcons.arrowDown}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Collapse in={switchToggle.exchangeIntRate}>
                    {" "}
                    <p className="text-sm font-semibold text-zinc-600">
                      Interest Rate
                    </p>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;
