import React from "react";
import { Navigate } from "react-router-dom";
import { isLoggedIn } from "./utils/apiHandlers";

const PrivateRoute = ({ children }) => {
  const login = isLoggedIn();
  return login ? children : <Navigate to="/" />;
};

export default PrivateRoute;
