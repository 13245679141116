import * as yup from "yup";

export const signUpValidation = yup.object().shape({
  email: yup
    .string()
    .required("Please enter you email address")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    ),
});
export const loginValidation = yup.object().shape({
  email: yup
    .string()
    .required("Please enter you email address")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    ),
  password: yup
    .string()
    .required("Please enter password.")
    .matches(
      /^(?=.*[A-Z])(?=.*[~!@#$%^&*()/_=+[\]{}|;:,.<>?-])(?=.*[0-9])(?=.*[a-z]).{8,14}$/,
      "Only accept One Uppercase and atleast one special characters and numbers."
    )
    .min(8, "Minimum 8 characters is required."),
});
export const subAdminValidation = yup.object().shape({
  fullname: yup.string().required("Please enter you full name"),
  email: yup
    .string()
    .required("Please enter you email address")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    ),
  password: yup
    .string()
    .required("Please enter password.")
    .matches(
      /^(?=.*[A-Z])(?=.*[~!@#$%^&*()/_=+[\]{}|;:,.<>?-])(?=.*[0-9])(?=.*[a-z]).{8,14}$/,
      "Only accept One Uppercase and atleast one special characters and numbers."
    )
    .min(8, "Minimum 8 characters is required."),
});
export const currencyValidation = yup.object().shape({
  currency: yup.string().required("Required"),
  currentRate: yup.string().required("Required"),
  agentCommission: yup
    .number()
    .positive()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Required"),
  enhancer: yup
    .number()
    .positive()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Required"),
  platformFee: yup
    .number()
    .positive()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Required"),
});
export const userValidation = yup.object({
  fullname: yup.string().required("Full name is required"),
  email: yup
    .string()
    .required("Email is required")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    ),
  mobile: yup
    .string()
    .required("Mobile number is required")
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      "Please enter only 10 digit number."
    ),
  city: yup.string().nullable(),
  country: yup.string().nullable(),
  whatsapp: yup
    .string()
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      "Please enter only 10 digit number."
    )
    .nullable(),
  telegram: yup.string().nullable(),
});
export const partnerValidation = yup.object({
  fullname: yup.string().required("Full name is required"),
  email: yup
    .string()
    .required("Email is required")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    ),
  mobile: yup
    .string()
    .required("Mobile number is required")
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      "Please enter only 10 digit number."
    ),
  city: yup.string().required("City is required"),
  country: yup.string().required("Country is required"),
  state: yup.string().required("State is required"),
  timeZone: yup.string().required("TimeZone is required"),
  whatsapp: yup
    .string()
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      "Please enter only 10 digit number."
    )
    .nullable(),
  telegram: yup.string().required("Telegram is required"),
});
export const QrCodeValidation = yup.object().shape({
  upi: yup.string().required("Required"),
  image: yup.string().required("Required"),
});
export const RejectionValidation = yup.object().shape({
  rejectionReason: yup.string().required("Please enter rejection reason"),
});

export const AccountValidation = yup.object().shape({
  holderName: yup
    .string()
    .required("Bank Account Holder name is required")
    .matches(
      /^[a-zA-Z\s]+$/,
      "Bank Account Holder name must contain only alphabet characters"
    ),
  accountNumber: yup
    .string()
    .required("Account Number Required")
    .matches(/^[0-9]{11,}$/, "Account Number must be at least 11 digits"),
  bankName: yup
    .string()
    .required("Bank Name Required")
    .matches(
      /^[A-Za-z\s]+$/,
      "Bank Name can only contain alphabetic characters and spaces"
    ),
  ifsc: yup
    .string()
    .required("Ifsc Code Required")
    .matches(/^[A-Za-z]{4}[0-9]{7}$/, "Invalid IFSC code"),
});

export const changePasswordValidation = yup.object().shape({
  oldPassword: yup.string().required("Please enter old password"),
  newPassword: yup
    .string()
    .required("Please enter new password.")
    .matches(
      /^(?=.*[A-Z])(?=.*[~!@#$%^&*()/_=+[\]{}|;:,.<>?-])(?=.*[0-9])(?=.*[a-z]).{8,14}$/,
      "Only accept One Uppercase and atleast one special characters and numbers."
    )
    .min(8, "Minimum 8 characters is required."),
  confirmPassword: yup
    .string()
    .required("Please enter confirm password")
    .oneOf([yup.ref("newPassword")], "Passwords not matched"),
});

export const merchantValidationSchema = yup.object().shape({
  fullname: yup.string().required("Full name is required"),
  email: yup
    .string()
    .required("Email is required")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    ),
  websiteDomain: yup.string().required("Website Domain is required"),
  platformName: yup.string().required("Platform Name is required"),
  callbackUrl: yup.string().required("Call Back Url is required"),
  uin: yup.string().required("uin is required"),
  walletAddress: yup.string().required("Wallet Address is required"),
  howToFind: yup.string().required("How to find is required"),
});
