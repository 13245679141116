export const contracts = {
  testnet: {
    ventursaToken: "0x36eb608012ecde8d4c2A07B9ae8F2a85eEB7Dcc4",
    ventursaTokenExchange: "0x32071F606Ba9bf06700dC1F4302895405a2a2Ea5",
    owner: "0x3C00E86f70fbFbeE84dffe6547E84A179447A68e",
    testERC20: "0x1E17a11Dd240eBF41E8eEfD280BFAd782A36d2a4",
  },
  mainnet: {
    ventursaToken: "",
    ventursaTokenExchange: "",
    owner: "",
    testERC20: "",
  },
};
export const chain =
  process.env.REACT_APP_NETWORK === "testnet" ? "mumbai" : "polygon";
export const exploreUrl = "https://amoy.polygonscan.com";
